label{


    color:blue;
}
.loginform{
    text-align: center;
    margin-left: 150px;
    padding-top: 10px;
    width: 600px;
    padding: 2px;
    border: 1px solid blue;
    opacity: 0.9;



}
.loginform:hover{
    border: 1px solid black;


}
.login{
    padding: 3px;
    border-radius: 10px;
    width:250px;
    color:black;
    
}
legend{
    color:yellow;
}
legend:hover{
    color: blue;
}
.btnlogin{
    background-color: yellow;
    padding-bottom: 10px;
    width: 125px;
    padding-top: 5px;
    border-radius: 5px;
}
.btnlogin:hover{
    color:white;
    background-color: green;
}
.login:focus{
    font-size: 22px;
}
label:hover{
    color:black
}
.btnlogin:focus{
width:105px;

}