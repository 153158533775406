body {
  border: 0px  solid blue;
  overflow: scroll;
  background-color: white;
  background-size: cover;
  width: 100%;
  height: 830px;
  background-size: cover;
  background-image:linear-gradient( to right ,rgba(0, 0, 0,0.8),rgba(0, 0, 0,0.5)),url("./spacex-OHOU-5UVIYQ-unsplash.jpg");;

  margin: 0;
  

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.codearea{
  margin: 15px;
  background-color: azure;


}

.footerelements{
  margin-top: 105px;
  color:rgba(220, 223, 67, 0.712);
  text-shadow:1px 1px 20px #f5f5f5;
   margin-left: 250px ;


   padding: 80px;
}
.app{

}
.footerelements:hover{

  color: blue;
}

.downbar{
  margin-top: 80px;
  margin-bottom: 5px;
  width: 100%;
  height: 80px;
  border: 2px solid skyblue;


}
.learning{
display:flex;
background-color: white;

}

.sidebar{
  width: 250px;
height :820px;
border-radius: 5px;
overflow: scroll;
background-color: rgba(0, 266, 266,.1);

}
.adressbar{
  width: 100%;
  height: 750px;
  margin:1px;
  background-size: cover;


}


#Navelements{
font-family: cursive;

}
#Navelements:hover{
  color: white;
  text-shadow: 1px 1px 10px ;

}

.dropm{
  font-family: cursive;
  font-weight: bold;

}
.dropm:focus{
  background-color: blue;
}
.glow{
  background-color: yellow;
  box-shadow: 1px 1px 20px yellow;

    color: black;
    font-family: cursive;
    font-size: 14px;
    padding: 12px;
    width: 200px;
    border-collapse: collapse;


}

.glow:hover{
  box-shadow: 1px 1px  21px rgb(38, 101, 218);
  background-color: blue;
  transform: rotateZ(200deg);
  @keyframes identifier {
    
  }



}

.learnsubjects{
width: 100%;
height: 820px;
margin-left:1px ;
background-color:white;
margin-top: 3px;
padding: 13px;
overflow: scroll;

}



.login{
  text-decoration: none;
  color: azure; 

}
p::selection{
  background-color: blueviolet;
  color: yellow;
}




  #searchid{
    position: relative;
    right: 0;
    color: brown;
    padding-top: 30px;
    border-top: 10px  solid rgba(44, 44, 216, 0.8);
    border: 2px solid white;
  }

a {
text-decoration: none;
}
a:hover{
  color: rgba(5, 4, 4,0.5);

  }
#searchid{
  padding: 10px;
  border-radius:7px  ;
  text-align: center;

}
.img1{
  width: 30px;
height: 40px;
border-radius: 50% auto;

}
.home{
  width: 100%;
  height: 830px;
  background-size: cover;
  background-image:linear-gradient( to right ,rgba(0, 0, 0,0.8),rgba(0, 0, 0,0.5)),url("./jonatan-pie-h8nxGssjQXs-unsplash.jpg");;

  
}
.boxtext{
  width: 300px;
  height: 100px;
  margin: 220px auto;
  margin-left: 280px;


}